import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import { CircularProgress, useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { useEffect, useMemo, useState, } from 'react';
import { useAppDispatch, useAppSelector } from '@app/store/Hooks';
import { cancelEditStep, selectInvestAmount, selectStopCopyingThreshould, submitStep, } from '@entities/portfolio';
import { numberToFixed2 } from '@shared/lib/numbers';
import { useGetExchangeAccountsQuery } from '@shared/api/exchange/api';
import { Body, BodySemiBold, BodyMedium, BodyMediumSemiBold, BodySmall, } from '@components/styled/Typography';
import QuantityInput from '@shared/ui/inputs/QuantityInput';
import SliderInput from '@shared/ui/inputs/SliderInput';
import QuestionMarkTooltip from '@shared/ui/tooltips/QuestionMarkTooltip';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { IsDefined } from '@utils/js-ts';
import BidToQueueStep from './BidToQueueStep';
const Column = styled(ContainerColumn)(() => ({
    height: 'auto',
}));
const StopCopyingContainer = styled(ContainerRow)(() => ({
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
}));
const StopCopyingLabel = styled(ContainerRow)(props => ({
    height: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.s,
}));
const LoaderContainer = styled(ContainerColumn)(() => ({
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
    minHeight: 100,
}));
const TextRow = styled(ContainerRow)(() => ({
    height: 'auto',
    alignItems: 'center',
    gap: 7,
}));
const StepType = 'invest_amount';
const StopCopyingDetailedInfo = ({ investAmount, stopCopyingPercent, colorVariant = 'highlight', }) => {
    const theme = useTheme();
    const stopCopyPercentValue = useMemo(() => (investAmount * stopCopyingPercent) / 100, [investAmount, stopCopyingPercent]);
    const textColor = useMemo(() => {
        let color;
        switch (colorVariant) {
            case 'plain-white':
                color = theme.customColors.surface.surface;
                break;
            case 'highlight':
            default:
                color = theme.palette.text.secondary;
        }
        return color;
    }, [colorVariant, theme]);
    const highlightColor = useMemo(() => {
        let color;
        switch (colorVariant) {
            case 'plain-white':
                color = theme.customColors.surface.surface;
                break;
            case 'highlight':
            default:
                color = theme.palette.text.primary;
        }
        return color;
    }, [colorVariant, theme]);
    return (_jsx(BodySmall, { color: textColor, children: Trans({
            ns: 'translation',
            i18nKey: 'portfolio.subscription.stop_copying_detailed',
            values: {
                reaches: numberToFixed2(investAmount - stopCopyPercentValue),
                estimatedPNL: numberToFixed2(stopCopyPercentValue),
            },
            components: [(_jsx(BodySemiBold, { display: 'inline', 
                    // TODO: resolve.
                    // for some reason typescript does not see the 'component' prop
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component: 'span', color: highlightColor }, 'bold'))],
        }) }));
};
const EnterInvestmentStepCollapsed = ({ investAmount, stopCopyingPercent, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { subscriptionData } = useAppSelector(state => state.portfolioSubscription);
    return (_jsxs(_Fragment, { children: [_jsxs(TextRow, { children: [_jsx(BodyMedium, { color: theme.palette.text.secondary, children: `${t('portfolio.subscription.amount')}:` }), _jsx(BodyMediumSemiBold, { children: `$${subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.investAmount}` })] }), _jsxs(TextRow, { children: [_jsx(BodyMedium, { color: theme.palette.text.secondary, children: `${t('portfolio.subscription.stop_copying')}:` }), _jsx(BodyMediumSemiBold, { children: `${subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.stopCopyingThreshould}%` }), _jsx(QuestionMarkTooltip, { title: (_jsx(StopCopyingDetailedInfo, { investAmount: investAmount, stopCopyingPercent: stopCopyingPercent, colorVariant: 'plain-white' })), placement: 'top-start', arrow: true })] })] }));
};
const BidToQueueEnterInvestmentStep = ({ stepNumber, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { portfolio, subscriptionData } = useAppSelector(state => state.portfolioSubscription);
    const [investAmount, setInvestAmount] = useState((portfolio === null || portfolio === void 0 ? void 0 : portfolio.min_balance) || 0.00);
    const [stopCopying, setStopCopying] = useState(15); // TODO: get recommended stop loss
    useEffect(() => {
        if (IsDefined(subscriptionData) && IsDefined(subscriptionData.investAmount)) {
            setInvestAmount(subscriptionData.investAmount);
        }
    }, [subscriptionData]);
    useEffect(() => {
        if (IsDefined(subscriptionData) &&
            IsDefined(subscriptionData.stopCopyingThreshould)) {
            setStopCopying(subscriptionData.stopCopyingThreshould);
        }
    }, [subscriptionData]);
    const onBetAmountChange = (value) => {
        setInvestAmount(value);
    };
    const onStopCopyingChange = (value) => {
        setStopCopying(value);
    };
    const { data: exchanges, isFetching, isError } = useGetExchangeAccountsQuery();
    const selectedExchange = useMemo(() => exchanges === null || exchanges === void 0 ? void 0 : exchanges.find(e => e.id === (subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.exchangeId)), [exchanges, subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.exchangeId]);
    const onAction = (actionType) => {
        if (actionType === 'continue') {
            dispatch(selectInvestAmount(investAmount));
            dispatch(selectStopCopyingThreshould(stopCopying));
            dispatch(submitStep(StepType));
        }
        else if (actionType === 'update') {
            dispatch(selectInvestAmount(investAmount));
            dispatch(selectStopCopyingThreshould(stopCopying));
            dispatch(cancelEditStep(StepType));
        }
        else if (actionType === 'cancel' && IsDefined(subscriptionData)) {
            if (IsDefined(subscriptionData.investAmount)) {
                setInvestAmount(subscriptionData.investAmount);
            }
            if (IsDefined(subscriptionData.stopCopyingThreshould)) {
                setStopCopying(subscriptionData.stopCopyingThreshould);
            }
        }
    };
    return (_jsxs(_Fragment, { children: [isFetching && (_jsx(LoaderContainer, { children: _jsx(CircularProgress, {}) })), !isFetching &&
                !isError &&
                IsDefined(portfolio) &&
                IsDefined(selectedExchange) && (_jsxs(BidToQueueStep, { type: StepType, stepNumber: stepNumber, title: t('portfolio.subscription.enter_invest_amount_label'), tooltipText: t('portfolio.subscription.enter_invest_amount_tooltip'), onAction: onAction, collapsedContent: (_jsx(EnterInvestmentStepCollapsed, { investAmount: investAmount, stopCopyingPercent: stopCopying })), children: [_jsxs(Column, { children: [_jsx(BodyMedium, { children: t('portfolio.subscription.amount') }), _jsx(Body, { color: theme.palette.text.secondary, children: t('portfolio.subscription.total_balance_available', 
                                // TODO-FIX-MOCKS: aggregate totalBalance from ExchangeWallets
                                { balance: 0 }) })] }), _jsx(SliderInput, { value: investAmount, onChange: onBetAmountChange, 
                        // TODO-FIX-MOCKS: aggregate totalBalance from ExchangeWallets
                        minMaxValues: [portfolio.min_balance, 0] }), _jsxs(StopCopyingContainer, { children: [_jsxs(Column, { children: [_jsxs(StopCopyingLabel, { children: [_jsx(BodyMedium, { children: t('portfolio.subscription.stop_copying') }), _jsx(QuestionMarkTooltip, { title: t('portfolio.subscription.stop_copying_tooltip'), placement: 'top-start', arrow: true })] }), _jsx(BodySmall, { color: theme.palette.text.secondary, children: t('portfolio.subscription.stop_copying_recommended', { percent: 15 }) })] }), _jsx(QuantityInput, { variant: 'percent', value: stopCopying, incDecStep: 1, min: 0, max: 100, onChange: onStopCopyingChange })] }), _jsx(StopCopyingDetailedInfo, { investAmount: investAmount, stopCopyingPercent: stopCopying })] }))] }));
};
export default BidToQueueEnterInvestmentStep;
